<template>
    <SignUp/>
</template>

<script>
import SignUp from '../../components/auth/SignUp'
export default {

    components: {
        SignUp
    }
}
</script>

<style>

</style>
