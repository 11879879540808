import { mapState } from "vuex";
import trans from "../../../../translations";
import http from "../../../http/index";
import api from "../../../apis/index";


export const userNotification = {
    data() {
      return {

        tran:''
      }


    },

watch:{

    notifyUserFromDomStore() {
        if (this.notifyUserFromDomStore) {
            this.$toast.warn({
                title:this.tran.sorry,
                icon:require("../../../assets/images/cautionicon.png"),
                timeOut: 12000,
                message: this.userNotificationMessage,
                position: "top center"
            });
          }
          this.$store.commit("domElements/closeNotificationMessage");
    },

    notifyUser() {
        if (this.notifyUser) {
    
          this.$toast.success({
            title: this.tran.success,
            icon: require("../../../assets/images/successicon.png"),
            timeOut: 12000,
            message: this.notifyMessage,
            position: "top center"
          });
        }
  
        this.$store.commit("market/closeNotification");
      },

      notifyUserOfSuccess() {
        
        if (this.notifyUserOfSuccess) {
      
          this.$toast.success({
            title: this.tran.success,
            icon: require("../../../assets/images/successicon.png"),
            timeOut: 12000,
            message: this.userNotificationMessage,
            position: "top center"
          });
        }
  
        this.$store.commit("domElements/closeSuccessNotificationMessage");
      },

},

computed:{

    ...mapState({
        userNotificationMessage: state => state.domElements.userNotificationMessage,
        notifyUserFromDomStore: state => state.domElements.notifyUserFromDomStore,
        notifyMessage: state => state.market.notifyMessage,
        notifyUser: state => state.market.notifyUser,

        notifyUserOfSuccess: state => state.domElements.notifyUserOfSuccess,
       
    })
},

mounted(){

    this.tran = trans

    $(document).ready(function () {
        $('input').each(function () {
            if ($(this).val() !== '') {
                $(this).parents(".form-group")
                    .addClass("focused");
            }
        });
    });

    setTimeout(() => {

        $("input").focus(function () {
            $(this)
                .parents(".form-group")
                .addClass("focused");
        });

        $(".form-label").click(function () {
            $(this)
                .parents(".form-group")
                .addClass("focused");
        });

        $("input").blur(function () {
            var inputValue = $(this).val();
            if (inputValue == "") {
                $(this).removeClass("filled");
                $(this)
                    .parents(".form-group")
                    .removeClass("focused");
            } else {
                $(this).addClass("filled");
            }
        });
    }, 500);

}

}

// commit("saveNotifyMessage", "product removed successfully");
// commit("openNotification");

// commit("market/emptyCartItems", null, { root: true });