<template>
  <div>
    <div class="login-div sign-up" style="background-size:contain">
      <!-- <img src="../../assets/images/logo2.png" height="50" class="mb-4">
    <input type="email" class="form-control mb-4" :placeholder="tran.email">
    <input type="password"   class="form-control mb-4"          :placeholder="tran.password">
    <input type="button" class="submit-btn" :value="tran.login">
      <p class="mt-4">{{tran.forgotPassword}}</p>-->
      <div class="row">
        <div class="col-lg-8 col-12"></div>
        <div class="col-lg-4 col-12 login-container text-center">
          <div>
            <img
              src="../../assets/images/logo.png"
              height="70"
              class="auth-img"
              loading="lazy"
              @click="sendToMarket"
            >
          </div>
                      <div class="alert alert-success" role="alert" v-if="transferActive && transferDetails.type !== '' && transferDetails.iu == 0">
  <h5 class="alert-heading">Welcome to reQuid </h5>
  <p v-if="transferDetails.type !== 'fund'">Sign up to claim your investment</p>
    <p v-else>Sign up to claim the funds transfered to you</p>
</div>

          <div class="form-div">
            <h4 class="mb-4 head-font">{{tran.signup}}</h4>
            <div class="form-group">
              <label
                class="form-label"
                @click.prevent="focusOnInput($event)"
                for="first"
              >{{tran.firstName}}</label>
              <input
                class="form-input"
                v-model="formdata.first_name"
                @keydown.enter.prevent="checkEnterButton"
                type="text"
                autocomplete="off">
            </div>
            <div class="form-group">
              <label
                class="form-label"
                @click.prevent="focusOnInput($event)"
                for="first"
              >{{tran.lastName}}</label>
              <input
                v-model="formdata.last_name"
                @keydown.enter.prevent="checkEnterButton"
                class="form-input"
                type="text"
                autocomplete="off"
              >
            </div>
            <div class="form-group">
              <label
                class="form-label"
                @click.prevent="focusOnInput($event)"
                for="last"
              >{{tran.email}}</label>
              <input
                v-model="formdata.email"
                class="form-input"
                type="email"
                required
                autocomplete="false"
                @keydown.enter.prevent="checkEnterButton"
              >
            </div>
            <div class="form-group">
              <label
                class="form-label"
                id="myID"
                @click.prevent="focusOnInput($event);"
                for="last"
              >{{tran.phone}}</label>
              <div style="display:flex; justify-content:center;" class="darkBorderBottom">
                <input
                 
                  id="number1"
                  class="form-input borderBottomInput"
                  type="number"
                  autocomplete="none"
                  maxlength="4"
                  @keyup="getNumber('number1',$event)"
                  style="max-width:45px;border-bottom:1px solid transparent"
                >
                <input
               
                  id="number2"
                  class="form-input borderBottomInput"
                  type="number"
                  autocomplete="none"
                  @click="checkifInputIsEmpty($event)"
                  maxlength="4"
                  @keyup="getNumber('number2', $event)"
                  style="max-width:45px;border-bottom:1px solid transparent"
                >
                <input
              
                  id="number3"
                  class="form-input borderBottomInput"
                  type="number"
                  autocomplete="none"
                  maxlength="3"
                  @click="checkifInputIsEmpty($event)"
                  @keyup="getNumber('number3',$event)"
                  style="max-width:45px;border-bottom:1px solid transparent"
                >
              </div>
            </div>

            <div class="form-group">
              <label
                class="form-label"
                @click.prevent="focusOnInput($event)"
                for="last"
              >{{tran.password}}</label>
              <div class="eye-div">
                <input
                  v-model="formdata.password"
                  class="form-input"
                  :type="hideInput"
                  autocomplete="false"
                   @keyup="changeInputType"
                  @keydown.enter.prevent="checkEnterButton"
                >
                <i class="fa fa-eye-slash" @click="showInputText" v-if="showEye"></i>
                <i class="fa fa-eye" @click="hideInputText" v-if="hideEye"></i>
              </div>
            </div>

            <!-- Ref Code -->
            <div class="form-group">
              <label
                class="form-label"
                @click.prevent="focusOnInput($event)"
                for="last"
              >{{tran.referral_code}}</label>
              <input
                v-model="formdata.ref"
                class="form-input"
                type="text"
                autocomplete="none"
                maxlength="11"
                @keydown.enter.prevent="checkEnterButton"
              >
            </div>

            <div class="custom-control text-center custom-checkbox mt-1 mb-2 my-1 mr-sm-2">
              <input
                type="checkbox"
                class="custom-control-input"
                id="customControlInline"
                @click="checkForTerms($event)"
              >
              <label class="custom-control-label" for="customControlInline">
                <b>
                  <span>{{tran.i_agree}}</span>
                  <router-link to="/terms" target="_blank">
                    <b style="cursor:pointer; text-decoration:underline">{{tran.terms}}</b>
                  </router-link>
                </b>&nbsp;
                <span>{{tran.and}}&nbsp;</span>
                
                <b>
                  <router-link to="/privacy" target="_blank">
                    <span style="cursor:pointer; text-decoration:underline">{{tran.privacy_policy}}</span>
                  </router-link>
                </b>
              </label>
            </div>
            <div class="form-group">
              <button
                type="button"
                class="submit-btn mt-2"
                :class="{'fade-elem': loader || !termsPrivacy}"
                @click.prevent="signUpUser"
              >
                {{ loader ? '' : tran.signup }}
                <span class="loader mt-1" v-if="loader"></span>
              </button>
            </div>
            <div>
              <h6 @click="sendToLogin" class="underline fw-600 mt-4">{{tran.login}}</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import trans from "../../../translations";
import { mapState } from "vuex";

import { userNotification } from "../dashboard/mixins/notifyUser.js";
export default {
  mixins: [userNotification],
  data() {
    return {
      tran: {},
      hideEye: false,
      showEye: true,
      hideInput: "text",
      termsPrivacy: false,
      spacedNumber: "",
      phoneNumber: "",
      changeBorderBottomToGreen: false
    };
  },
  computed: {
    ...mapState({
      formdata: state => state.auth.signUp,
      loader: state => state.auth.loader,
      token: state => state.auth.token,
                transferActive: state => state.auth.transferActive,
                      transferDetails: state => state.auth.transferDetails,
    })
  },

  methods: {
    checkifInputIsEmpty(e) {
      if (e.target.value == "" && $("#number1").val() == "") {
        $("#number1").focus();
      }
    },
  changeInputType(){
             this.hideInput = 'password'
        },
    getNumber(number, e) {
      let key = e.keyCode;
      if (key == 8 && e.target.value.length == "" && number == "number2") {
        $("#number1").focus();
      }

      if (key == 8 && e.target.value.length == "" && number == "number3") {
        $("#number2").focus();
      }

      if (number == "number1" && e.target.value.length == 4) {
        $("#number2").focus();
      } 
      else if (number == "number2" && e.target.value.length == 3) {
        $("#number3").focus();
      }
      if (e.target.value.length > 4 && number == "number3") {
        e.target.value = e.target.value.toString().substr(0, 4);
      } else if (e.target.value.length > 3 && number == "number2") {
        e.target.value = e.target.value.toString().substr(0, 3);
      } else if (e.target.value.length > 4 && number == "number1") {
        e.target.value = e.target.value.toString().substr(0, 4);
      }
    },

    sendToMarket() {
      this.$router.push("/market");
    },

    signUpUser() {

        let phone = $('#number1').val()+$('#number2').val()+$('#number3').val()
       
      this.$store.dispatch("auth/registerUser",phone);

      try {
        ga("send", "event", "user acquisition", "Sign up");
      } catch {}
    },
    checkEnterButton() {
      if (event.keyCode === 13) {
        this.signUpUser();
      }
    },

    showInputText() {
      this.hideInput = "text";
      this.showEye = false;
      this.hideEye = true;
    },

    focusOnInput(e) {
      e.target.parentNode.querySelector(".form-input").focus();
    },
    hideInputText() {
      this.hideInput = "password";
      this.showEye = true;
      this.hideEye = false;
    },
    sendToLogin() {
      this.$router.push("/login");
    },

    checkForTerms(event) {
      if (event.target.checked) {
        this.termsPrivacy = true;
      } else {
        this.termsPrivacy = false;
      }
    }
  },

  mounted() {
    this.tran = trans;

    this.$helpers.log(this.$route.query, "query param");
    if (Object.keys(this.$route.query).length !== 0) {
      this.$store.commit("auth/saveSignUpQuery", this.$route.query);
    }

    //Check if user is logged in, if logged in redirect back to market
    if (this.token !== null) {
      this.$router.push("/market");
    }
        this.$store.commit('auth/stopLoader')
 this.$store.commit('auth/clearSignUpData')
    let refCode = localStorage.getItem("refCode");

    if (refCode !== "undefined" && refCode !== "null") {
      this.$store.commit("auth/saveRefCode", refCode);
    }
  }
};
</script>

<style scoped>
a {
  display: inline;
  color: #000;
  padding: 0px;
}

.darkBorderBottom {
  border-bottom: 1px solid #000;
}
.greenBorderBottom {
  border-bottom: 1px solid #58b46f !important;
}

.borderBottomInput {
  box-shadow: 0 1.1px 0 0 transparent !important;
}
@media (max-width: 767px) {
  .custom-control label {
    padding-top: 3px;
    font-size: 13px;
  }
}
</style>
